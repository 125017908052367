








































import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { Link } from "element-ui";
@Component({
    components: {
        "el-link": Link
    }
})
export default class Project extends Vue {
    navActived: string = ""; //项目导航栏actived
    pid = "";
    /* 项目导航栏参数 */
    get nav_p_opt() {
        let opt = [
            {
                to: "projectBasic",
                content: this.$t("project.basic"),
                isActived: "projectBasic"
            },
            {
                to: "/",
                content: "|",
                isActived: "/"
            },
            {
                to: "projectTech",
                content: this.$t("project.tech"),
                isActived: "projectTech"
            },
            {
                to: "/",
                content: "|",
                isActived: "/"
            },
            {
                to: "projectTeam",
                content: this.$t("project.team"),
                isActived: "projectTeam"
            },
            {
                to: "/",
                content: "|",
                isActived: "/"
            },
            {
                to: "projectFile",
                content: this.$t("project.file"),
                isActived: "projectFile"
            }
        ];
        return opt;
    }

    /* 项目导航栏点击事件 */
    handleNav(item: any) {
        if (item.to != "/") {
            this.$router.push({ name: item.to });
        }
    }

    /* 获取项目id，如通过项目列表进去则不需要 */
    async getProjectId() {
        const loading = this.$loading({
            lock: true,
            text: " ",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
        });

        const getopt = this.$api.apiList.getUserInfo;
        const opt = getopt();
        let result = await this.$axios({
            url: opt.url,
            method: opt.method,
            headers: opt.headers
        }).then((res: any) => {
            let pid = res.data.case_id;
            return pid;
        });
        loading.close();
        return result;
    }

    /**
     * 跳转到编辑项目页
     */
    goEdit() {
        this.getProjectId().then((res: any) => {
            this.$router.push({
                name: "editProject",
                params: {
                    step:
                        this.$route.name == "projectBasic"
                            ? "1"
                            : this.$route.name == "projectTech"
                            ? "2"
                            : this.$route.name == "projectTeam"
                            ? "3"
                            : "4",
                    pid: res
                }
            });
        });
    }

    @Watch("$route")
    routeChange(to: any, from: any) {
        this.navActived = to.name;
    }

    mounted() {
        if (this.$route.name) {
            this.navActived = this.$route.name;
        }
        this.getProjectId().then(res => {
            if (res) {
                this.pid = res;
            }
        });
    }
}
